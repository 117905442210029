import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_851_35243)">
        <path
          d="M7.33355 5.21883L3.75755 8.79483L2.81488 7.85216L8.00021 2.66683L13.1855 7.85216L12.2429 8.79483L8.66688 5.21883V13.3335H7.33355L7.33355 5.21883Z"
          fill="#56e658"
        />
      </g>
      <defs>
        <clipPath id="clip0_851_35243">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
