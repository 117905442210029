import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" width="24px" {...props}>
      <path
        d="M13.5 16.98L13.5 0.99998"
        stroke="#66605A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.502 12.9819L13.501 16.9829L9.49995 12.9819"
        stroke="#66605A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.5 10L5.5 0.94" stroke="#66605A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.50195 5.999L5.50095 10L1.49995 5.999"
        stroke="#66605A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{' '}
    </Svg>
  )
}

export default Icon
