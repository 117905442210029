import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg color='transparent' width="28" height="18" viewBox="0 0 28 18" fill="none" {...props}>
        <rect width="28" height="18" rx="9" fill="#3D3933" />
        <line x1="8.5" y1="6.5" x2="8.5" y2="11.5" stroke="white" strokeOpacity="0.4" strokeLinecap="round" />
        <line x1="14" y1="6.5" x2="14" y2="11.5" stroke="white" strokeOpacity="0.4" strokeLinecap="round" />
        <line x1="19.5" y1="6.5" x2="19.5" y2="11.5" stroke="white" strokeOpacity="0.4" strokeLinecap="round" />
    </Svg>
  )
}

export default Icon
