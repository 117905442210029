import React from 'react'
import { Flex, IconButton, CogIcon, useModal } from 'uikit'
import SettingsModal from './SettingsModal'

type Props = {
  mr?: string
}

const GlobalSettings = ({  mr = '32px' }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex>
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
        <CogIcon fill='none' height={24} width={24}/>
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
