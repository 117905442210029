import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M7.99917 5.33317C7.90717 5.33317 7.83251 5.40784 7.83317 5.49984C7.83317 5.59184 7.90784 5.6665 7.99984 5.6665C8.09184 5.6665 8.16651 5.59184 8.16651 5.49984C8.16651 5.40784 8.09184 5.33317 7.99917 5.33317"
          stroke="#66605A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
          stroke="#66605A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7.99984 8V11.3333" stroke="#66605A" strokeWidth="1.5" strokeLinejoin="round" />
    </Svg>
  )
}

export default Icon
