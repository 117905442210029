import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="11.059"
          cy="11.059"
          r="7.06194"
          stroke="#66605A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M20.0034 20.0034L16.0518 16.0518" stroke="#66605A" strokeWidth="1.5" strokeLinejoin="round" />
      </svg>
    </Svg>
  )
}

export default Icon
