import styled from 'styled-components'
import Text from '../Text/Text'
import { tags, scales, HeadingProps } from './types'

const style = {
  [scales.MD]: {
    fontSize: '24px',
    fontSizeLg: '24px',
  },
  [scales.LG]: {
    fontSize: '32px',
    fontSizeLg: '32px',
  },
  [scales.XL]: {
    fontSize: '32px',
    fontSizeLg: '32px',
  },
  [scales.XXL]: {
    fontSize: '32px',
    fontSizeLg: '32px',
  },
}

const Heading = styled(Text).attrs({ bold: true })<HeadingProps>`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 8px;

  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: ${({ scale }) => style[scale || scales.MD].fontSizeLg};
    margin-top: 0px;
  }
`

Heading.defaultProps = {
  as: tags.H2,
}

export default Heading
