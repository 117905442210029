import * as React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps & { fillColor?: string }> = ({ ...props }) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
          d="M20.334 6.66733C19.696 6.95067 19.0107 7.14133 18.2913 7.22733C19.026 6.78733 19.5893 6.09067 19.8547 5.26C19.168 5.66733 18.4067 5.96333 17.596 6.12333C16.9473 5.432 16.0233 5 15.0007 5C13.0367 5 11.4447 6.59267 11.4447 8.556C11.4447 8.83467 11.4767 9.10667 11.5367 9.366C8.58133 9.218 5.96133 7.802 4.20667 5.65C3.90133 6.17533 3.726 6.786 3.726 7.43867C3.726 8.672 4.35333 9.76067 5.30733 10.398C4.72467 10.3793 4.176 10.2193 3.69667 9.95333C3.69667 9.96867 3.69667 9.98267 3.69667 9.998C3.69667 11.7213 4.922 13.1587 6.54933 13.4847C6.25133 13.566 5.93667 13.6093 5.612 13.6093C5.38333 13.6093 5.16 13.5867 4.94333 13.546C5.396 14.9587 6.70933 15.9873 8.26533 16.016C7.04867 16.97 5.51533 17.5387 3.84867 17.5387C3.562 17.5387 3.27867 17.522 3 17.4887C4.574 18.4973 6.44267 19.086 8.45133 19.086C14.9927 19.086 18.5687 13.6673 18.5687 8.968C18.5687 8.814 18.5653 8.66067 18.5587 8.508C19.254 8.006 19.8573 7.38 20.334 6.66733Z"
          fill="#F5ECE2"
        />
    </Svg>
  )
}

export default Icon
