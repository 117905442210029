import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
<path d="M0 4.52991e-07H26.5312C27.9819 -0.000589442 29.3735 0.574973 30.4 1.60016C31.4242 2.62738 31.9995 4.0187 32 5.46935V32H0V4.52991e-07Z" fill="#FEE3C3"/>
<path d="M27.6577 24.7031V26.8761H17.3761V5.15555H21.7185V24.7031H27.6577ZM14.5665 5.79241C14.3657 5.59012 14.1269 5.42945 13.8639 5.31962C13.6009 5.20978 13.3187 5.15294 13.0337 5.15234H4.3457V26.8729H8.68813V7.32536H10.3169C10.4611 7.32572 10.5992 7.38327 10.7009 7.48538C10.7515 7.53507 10.7917 7.59433 10.8192 7.65971C10.8466 7.7251 10.8608 7.7953 10.8609 7.86622V26.8729H15.2033V7.32536C15.2054 7.03781 15.1502 6.75271 15.0409 6.48675C14.9315 6.2208 14.7703 5.9793 14.5665 5.7764V5.79241Z" fill="#0A0908"/>

    </Svg>
  );
};

export default Icon;
