import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ activeStroke, ...props }) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604"
      stroke={activeStroke}
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7043 15.3328H10.8973C10.2793 15.3328 9.77734 14.8318 9.77734 14.2128V12.0508H13.7043C14.6033 12.0508 15.3333 12.7798 15.3333 13.6798V13.7048C15.3323 14.6038 14.6033 15.3328 13.7043 15.3328V15.3328Z"
      stroke={activeStroke}
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3093 12.051H9.77734V9.80399C9.77734 9.17599 10.2863 8.66699 10.9143 8.66699H13.3093C14.2223 8.66699 14.9633 9.40699 14.9633 10.321V10.398C14.9633 11.311 14.2233 12.051 13.3093 12.051Z"
      stroke={activeStroke}
      strokeWidth="1.5"
    />
    <path d="M10.8899 8.66992H8.66992" stroke={activeStroke} strokeWidth="1.5" />
    <path d="M10.8999 15.3301H8.66992" stroke={activeStroke} strokeWidth="1.5" />
    <path d="M13.2305 7.56006V8.67006" stroke={activeStroke} strokeWidth="1.5" />
    <path d="M10.5 7.56006V8.67006" stroke={activeStroke} strokeWidth="1.5" />
    <path d="M10.5 16.4401V15.3301" stroke={activeStroke} strokeWidth="1.5" />
    <path d="M13.2305 16.4401V15.3301" stroke={activeStroke} strokeWidth="1.5" />
  </Svg>
)

export default Icon
