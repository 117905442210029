import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_851_29331)">
        <path
          d="M8.66645 10.7812L12.2425 7.20517L13.1851 8.14784L7.99979 13.3332L2.81445 8.14784L3.75712 7.20517L7.33312 10.7812V2.6665H8.66645V10.7812Z"
          fill="#FF3A3A"
        />
      </g>
      <defs>
        <clipPath id="clip0_851_29331">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
