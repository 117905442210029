import React from "react";
import * as IconModule from ".";
import { StyledAnimatedIconComponent, StyledIconContainer } from "./styles";
import { IconComponentType, SvgProps } from "./types";

const Icons = IconModule as unknown as { [key: string]: React.FC<SvgProps> };

const AnimatedIconComponent: React.FC<IconComponentType> = ({
  iconName,
  isActive = false,
  ...props
}) => {
  const IconElement = Icons[`${iconName}Icon`];
  return IconElement ? (
    <StyledAnimatedIconComponent isActive={isActive} hasFillIcon={false} {...props}>
      <StyledIconContainer activeBackgroundColor='background'>
        <IconElement activeStroke={isActive ? '#FEE3C3':"#66605A"} color='none' />
      </StyledIconContainer>
      {/* {hasFillIcon && (
        <StyledIconContainer activeBackgroundColor='background' {...props}>
          <IconElement  activeStroke='#FEE3C3' />
        </StyledIconContainer>
      )} */}
    </StyledAnimatedIconComponent>
  ) : null;
};

export default AnimatedIconComponent;
