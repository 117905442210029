import styled from 'styled-components'
import { variant as StyledSystemVariant } from 'styled-system'
import { ImageProps, Variant, variants } from './types'
import TokenImage from './TokenImage'
import TokenImage2 from './TokenImage2'

interface StyledImageProps extends ImageProps {
  variant: Variant
}

export const StyledPrimaryImage = styled(TokenImage)<StyledImageProps>`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 12px;
  height: 32px;
  width: 32px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  ${({ theme }) => theme.mediaQueries.sm} {
    height: ${({ width }) => width}px;
    width: ${({ width }) => width}px;
  }

  ${StyledSystemVariant({
    variants: {
      [variants.DEFAULT]: {
        bottom: 'auto',
        left: 0,
        right: 'auto',
        top: 0,
        zIndex: 5,
      },
      [variants.INVERTED]: {
        bottom: 0,
        left: '24px',
        right: 0,
        top: '10px',
        zIndex: 6,
      },
    },
  })}
`

export const StyledSecondaryImage = styled(TokenImage)<StyledImageProps>`
  position: absolute;
  width: 32px;
  border: 2px solid ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 12px;
  height: 32px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  ${({ theme }) => theme.mediaQueries.sm} {
    height: ${({ width }) => width}px;
    width: ${({ width }) => width}px;
  }

  ${StyledSystemVariant({
    variants: {
      [variants.DEFAULT]: {
        bottom: 0,
        left: 'auto',
        right: 0,
        top: 'auto',
        zIndex: 6,
      },
      [variants.INVERTED]: {
        bottom: 'auto',
        left: 0,
        right: 'auto',
        top: 0,
        zIndex: 5,
      },
    },
  })}
`

export const StyledPrimaryImage2 = styled(TokenImage2)<StyledImageProps>`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 12px;
  height: 48px;
  width: 48px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  ${({ theme }) => theme.mediaQueries.sm} {
    height: ${({ width }) => width}px;
    width: ${({ width }) => width}px;
  }

  ${StyledSystemVariant({
    variants: {
      [variants.DEFAULT]: {
        bottom: 'auto',
        left: 0,
        right: 'auto',
        top: 0,
        zIndex: 5,
      },
      [variants.INVERTED]: {
        bottom: 0,
        left: '32px',
        right: 0,
        top: '10px',
        zIndex: 6,
      },
    },
  })}
`

export const StyledSecondaryImage2 = styled(TokenImage2)<StyledImageProps>`
  position: absolute;
  width: 48px;
  border: 2px solid ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 12px;
  height: 48px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  ${({ theme }) => theme.mediaQueries.sm} {
    height: ${({ width }) => width}px;
    width: ${({ width }) => width}px;
  }

  ${StyledSystemVariant({
    variants: {
      [variants.DEFAULT]: {
        bottom: 0,
        left: 'auto',
        right: 0,
        top: 'auto',
        zIndex: 6,
      },
      [variants.INVERTED]: {
        bottom: 'auto',
        left: 0,
        right: 'auto',
        top: 0,
        zIndex: 5,
      },
    },
  })}
`
