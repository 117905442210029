import { Currency } from '@pancakeswap/sdk'
import React from 'react'
import styled from 'styled-components'
import CurrencyLogo from './CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: ${({ margin }) => margin && '4px'};
  position: relative;
  width: 88px;
  height: 64px;
`
const Wrapper1 = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  left: 0px;
  top: 0px;
  padding: 12px;
  border: 2px solid ${({ theme }) => theme.colors.backgroundAlt2};

  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`
const Wrapper2 = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  border: 2px solid ${({ theme }) => theme.colors.backgroundAlt2};
  left: 38px;
  top: 12px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 20,
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper margin={margin}>
      {currency0 && (
        <Wrapper1>
          <CurrencyLogo currency={currency0} size={`${size.toString()}px`} style={{ marginRight: '4px' }} />
        </Wrapper1>
      )}
      {currency1 && (
        <Wrapper2>
          <CurrencyLogo currency={currency1} size={`${size.toString()}px`} />
        </Wrapper2>
      )}
    </Wrapper>
  )
}
