import styled from "styled-components";
import { Flex } from "../Box";

const StyledBottomNav = styled(Flex)`
  position: fixed;
  display: flex;
  align-items:center ;
  bottom: 0px;
  padding-left: 24px;
  padding-right:24px;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundAlt2};
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*="TokenPocket_yiOS"] & {
    padding-bottom: 45px;
  }
  z-index: 20;
`;

export default StyledBottomNav;
