import React from 'react'
import { Box, BoxProps } from "uikit"

const Container: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box px={['16px', '16px' ,'16px', '16px']} mx="auto" maxWidth="1248px" {...props}>
    {children}
  </Box>
)

export default Container
