import React from 'react'
import styled from 'styled-components'
import {
  Flex,
  Heading,
  Text,
} from 'uikit'


interface Props {
  title: string
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
}

const CurrencyInputContainer = styled(Flex)`
  align-items: center;

  width: 100%;
`

const CurrencyInputHeader: React.FC<Props> = ({ title, subtitle}) => {

  return (
    <CurrencyInputContainer>
      <Flex width="100%" alignItems="flex-start" mb="16px">
        {/* {setIsChartDisplayed && (
          <ColoredIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
            {isChartDisplayed ? <ChartDisableIcon color="textSubtle" /> : <ChartIcon width="24px" color="textSubtle" />}
          </ColoredIconButton>
        )} */}
        <Flex flexDirection="column">
          <Heading as="h2" mb="8px" scale="lg">
            {title}
          </Heading>
          <Flex alignItems="center">
            <Text color="textSubtle" fontSize="14px">
              {subtitle}
            </Text>
          </Flex>
        </Flex>
        {/* <Flex>
          <NotificationDot show={expertMode}>
            <GlobalSettings color="textSubtle" mr="0" />
          </NotificationDot>
          <IconButton onClick={onPresentTransactionsModal} variant="text" scale="sm">
            <HistoryIcon color="textSubtle" width="24px" />
          </IconButton>
        </Flex> */}
      </Flex>
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
