import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <path d="M6.66602 10.6665L9.33268 7.99984L6.66602 5.33317" stroke="#66605A" />
    </Svg>
  )
}

export default Icon
