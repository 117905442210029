import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({  ...props }) => {
  return (
    <Svg viewBox="0 0 20 14" width="20px" height="20px" {...props}>
      <path
        d="M5.83333 1.75V2.91667H2.91667V11.0833H11.0833V8.16667H12.25V11.6667C12.25 11.8214 12.1885 11.9697 12.0791 12.0791C11.9697 12.1885 11.8214 12.25 11.6667 12.25H2.33333C2.17862 12.25 2.03025 12.1885 1.92085 12.0791C1.81146 11.9697 1.75 11.8214 1.75 11.6667V2.33333C1.75 2.17862 1.81146 2.03025 1.92085 1.92085C2.03025 1.81146 2.17862 1.75 2.33333 1.75H5.83333ZM10.2585 2.91667H7.58333V1.75H12.25V6.41667H11.0833V3.7415L7 7.82483L6.17517 7L10.2585 2.91667Z"
        fill="#DF130D"
      />
    </Svg>
  )
}

export default Icon
