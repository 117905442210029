import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604"
      stroke="#66605A"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7048 15.3328H10.8978C10.2798 15.3328 9.77783 14.8318 9.77783 14.2128V12.0508H13.7048C14.6038 12.0508 15.3338 12.7798 15.3338 13.6798V13.7048C15.3328 14.6038 14.6038 15.3328 13.7048 15.3328V15.3328Z"
      stroke="#66605A"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3098 12.051H9.77783V9.80399C9.77783 9.17599 10.2868 8.66699 10.9148 8.66699H13.3098C14.2228 8.66699 14.9638 9.40699 14.9638 10.321V10.398C14.9638 11.311 14.2238 12.051 13.3098 12.051Z"
      stroke="#66605A"
      strokeWidth="1.5"
    />
    <path d="M10.8899 8.66992H8.66992" stroke="#66605A" strokeWidth="1.5" />
    <path d="M10.8999 15.3301H8.66992" stroke="#66605A" strokeWidth="1.5" />
    <path d="M13.23 7.55957V8.66957" stroke="#66605A" strokeWidth="1.5" />
    <path d="M10.5 7.55957V8.66957" stroke="#66605A" strokeWidth="1.5" />
    <path d="M10.5 16.4401V15.3301" stroke="#66605A" strokeWidth="1.5" />
    <path d="M13.23 16.4401V15.3301" stroke="#66605A" strokeWidth="1.5" />
  </Svg>
)

export default Icon
