import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M0 8H16" stroke="#66605A" strokeWidth="2" />
      <path d="M8 0L8 16" stroke="#66605A" strokeWidth="2" />{' '}
    </Svg>
  )
}

export default Icon
