import { scales, variants } from './types'

export const scaleVariants = {
  [scales.MD]: {
    height: '48px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '40px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
  [scales.TAB]: {
    height: '44px',
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: 'primary',
    color: 'white',
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: '1.5px solid rgba(255, 255, 255, 0.1)',
    boxShadow: 'none',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: 'backgroundAlt2',
    boxShadow: 'none',
    color: 'text2',
  },
  [variants.SUBTLE]: {
    backgroundColor: 'textSubtle',
    color: 'backgroundAlt',
  },
  [variants.DANGER]: {
    backgroundColor: 'failure',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: 'primary',
    boxShadow: 'none',
  },
  [variants.LIGHT]: {
    backgroundColor: 'input',
    color: 'textSubtle',
    boxShadow: 'none',
  },
  [variants.TAB]: {
    backgroundColor: 'cardBorder2',
    color: 'textSubtle',
    boxShadow: 'none',
    border: '2px solid #24211E',
  },
  [variants.TABACTIVE]: {
    backgroundColor: 'backgroundAlt2',
    color: 'textSubtle',
    boxShadow: 'text2',
    border: '2px solid #24211E',
  },
  [variants.OUTLINED]: {
    backgroundColor: 'transparent',
    color: 'textSubtle',
    boxShadow: 'text2',
    border: '2px solid #24211E',
  },
}
