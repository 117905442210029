import React from 'react'
import { ChainId, Currency, currencyEquals, ETHER, Token } from '@pancakeswap/sdk'
import { Text } from 'uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

import { SUGGESTED_BASES } from '../../config/constants'
import { AutoColumn } from '../Layout/Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? theme.colors.cardBorder : theme.colors.backgroundAlt2)};
  display: flex;
  padding: 6px;
  width: 90px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.background};
  }

  background-color: ${({ theme, disable }) => disable && theme.colors.dropdown};
  opacity: ${({ disable }) => disable && '0.4'};
`

const ParentWrapper = styled.div`
display: flex ; 
gap: 8px ; 
width: calc(100% + 24px);
overflow-x: auto;

::-webkit-scrollbar{
  width: 0;
  height: 0;
}

& > div{
  flex-shrink: 0;
}
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Text fontSize="16px" fontWeight="500">
          {t('Common bases')}
        </Text>
        <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
      </AutoRow>
      <ParentWrapper>
        <BaseWrapper
        style={{display : 'flex' , justifyContent: 'center'}}
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo size='16px' currency={ETHER} style={{ marginRight: 8 }} />
          <Text fontSize='14px' fontWeight='500'>BNB</Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper style={{display : 'flex' , justifyContent: 'center'}} onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo size='16px' currency={token} style={{ marginRight: 8 }} />
              <Text fontSize='14px' fontWeight='500'>{token.symbol}</Text>
            </BaseWrapper>
          )
        })}
      </ParentWrapper>
    </AutoColumn>
  )
}
