import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Container from '../../../components/Layout/Container'
import Flex from '../../components/Box/Flex'
import Footer from '../../components/Footer'
import MenuItems from '../../components/MenuItems/MenuItems'
import { SubMenuItems } from '../../components/SubMenuItems'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './components/Logo'
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from './config'
import { NavProps } from './types'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  transform: translate3d(0, 0, 0);
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  background: ${({ theme }) => `${theme.colors.background}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.backgroundAlt}`};
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
`

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`
const TopPattern = styled.img`
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 80%;
    position: absolute;
    top: 0px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    max-width: unset;
    position: absolute;
    top: 0px;
  }
`
const TopRightPattern = styled.img`
  position: absolute;
  right: 0;
  display: none;
  top: -90px;
  z-index: 100;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;
  }
`
const TopLeftPattern = styled.img`
  position: absolute;
  left: 0;
  display: none;
  top:-50px;
  z-index:100;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;

  }
`
const LeftPattern = styled.img`
  position: absolute;
  left: 0;
  display: none;
  top: 100px;
  z-index: 100;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;
  }
`
const RightPattern = styled.img`
  position: absolute;
  right: 0;
  display: none;
z-index:100;
  top:100px;
  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;

  }
`
const LeftMask = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0;
  display: none;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;
  }
`
const RightMask = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0;
  display: none;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;

  }
`

const Menu: React.FC<NavProps> = ({
  userMenu,
  banner,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(window.pageYOffset)

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT

  const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly)
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly)

  return (
    <Wrapper>
      <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
        {banner && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>}
        <Container>
          <StyledNav>
            <Flex>
              <Logo isDark={isDark} href={homeLink?.href ?? '/'} />
              {!isMobile && <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="0px" />}
            </Flex>
            <Flex alignItems="center" height="100%">
              {/* {!isMobile && (
                <Box mr="12px">
                  <CakePrice cakePriceUsd={cakePriceUsd} />
                </Box>
              )}
              <Box mt="4px">
                <LangSelector
                  currentLang={currentLang}
                  langs={langs}
                  setLang={setLang}
                  buttonScale="xs"
                  color="textSubtle"
                  hideLanguage
                />
              </Box> */}
              {globalMenu} {userMenu}
            </Flex>
          </StyledNav>
        </Container>
      </FixedContainer>
      {subLinks && (
        <Container>
          <Flex justifyContent="space-around">
            <SubMenuItems
              items={subLinksWithoutMobile}
              mt={`${totalTopMenuHeight + 10}px`}
              activeItem={activeSubItem}
            />

            {subLinksMobileOnly?.length > 0 && (
              <SubMenuItems
                items={subLinksMobileOnly}
                mt={`${totalTopMenuHeight + 1}px`}
                activeItem={activeSubItem}
                isMobileOnly
              />
            )}
          </Flex>
        </Container>
      )}
      <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : '0'}>
        <TopPattern src="/images/top-pattern.png" />
        <TopLeftPattern src="/images/top-left-pattern.png" />
        <TopRightPattern src="/images/top-right-pattern.png" />
        <LeftPattern src="/images/left-pattern.png" />
        <RightPattern src="/images/right-pattern.png" />
        <LeftMask src="/images/left-mask.png" />
        <RightMask src="/images/right-mask.png" />
        <Inner isPushed={false} showMenu={showMenu}>
          <Container>{children}</Container>
          {!isMobile ? (
            <Footer
              items={footerLinks}
              isDark={isDark}
              toggleTheme={toggleTheme}
              langs={langs}
              setLang={setLang}
              currentLang={currentLang}
              cakePriceUsd={cakePriceUsd}
              buyCakeLabel={buyCakeLabel}
              mb={[`${MOBILE_MENU_HEIGHT}px`, null, '0px']}
            />
          ) : (
            <div style={{ height: '50px', marginBottom: `${MOBILE_MENU_HEIGHT}px` }}> </div>
          )}
        </Inner>
      </BodyWrapper>
      {isMobile && <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
    </Wrapper>
  )
}

export default Menu
