import { Currency, Percent, Price } from '@pancakeswap/sdk'
import React from 'react'
import styled from 'styled-components'

import { Text } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { AutoColumn } from '../../components/Layout/Column'
import { AutoRow } from '../../components/Layout/Row'
import { ONE_BIPS } from '../../config/constants'
import { Field } from '../../state/mint/actions'

const AutoRow0 = styled(AutoRow)`
  border-bottom: 1px dashed rgba(256, 256, 256, 0.1);
`

function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
}) {
  const { t } = useTranslation()
  return (
    <AutoColumn gap="md">
      <AutoRow gap="4px">
        <AutoRow0>
          <Text mb="12px" fontSize="14px">
            {noLiquidity ? t('Initial prices and pool share') : t('Prices and pool share')}
          </Text>
        </AutoRow0>
        <AutoRow justify="space-between">
          <Text fontSize="14px" color="textSubtle" pt={1}>
            {t('%assetA% per %assetB%', {
              assetA: currencies[Field.CURRENCY_B]?.symbol ?? '',
              assetB: currencies[Field.CURRENCY_A]?.symbol ?? '',
            })}
          </Text>
          <Text fontSize="14px" color="primary">
            {price?.toSignificant(6) ?? '-'}
          </Text>
        </AutoRow>
        <AutoRow justify="space-between">
          <Text fontSize="14px" color="textSubtle" pt={1}>
            {t('%assetA% per %assetB%', {
              assetA: currencies[Field.CURRENCY_A]?.symbol ?? '',
              assetB: currencies[Field.CURRENCY_B]?.symbol ?? '',
            })}
          </Text>
          <Text fontSize="14px">{price?.invert()?.toSignificant(6) ?? '-'}</Text>
        </AutoRow>
        <AutoRow justify="space-between">
          <Text fontSize="14px" color="textSubtle" pt={1}>
            {t('Share of Pool')}
          </Text>
          <Text fontSize="14px">
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </Text>
        </AutoRow>
      </AutoRow>
    </AutoColumn>
  )
}

export default PoolPriceBar
