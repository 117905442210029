import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="59" height="58" viewBox="0 0 59 58" fill="none" {...props}>
      <path
        d="M29.5 0C13.5094 0 0.5 13.0094 0.5 29C0.5 44.9906 13.5094 58 29.5 58C45.4906 58 58.5 44.9906 58.5 29C58.5 13.0094 45.4906 0 29.5 0ZM29.5 54.8648C22.3955 54.8648 15.9515 51.9852 11.2723 47.3326C9.40371 45.4746 7.8175 43.3332 6.58244 40.9799C4.70153 37.3962 3.63518 33.3205 3.63518 29C3.63518 14.7381 15.2381 3.13518 29.5 3.13518C36.2647 3.13518 42.4301 5.74678 47.0439 10.0134C49.436 12.2252 51.4114 14.8815 52.8357 17.8505C54.4562 21.2285 55.3648 25.0101 55.3648 29C55.3648 43.2619 43.7619 54.8648 29.5 54.8648Z"
        fill="#DF130D"
      />
      <path
        d="M32.5646 36.7613C36.0043 37.4806 39.094 39.4145 41.2647 42.2065L43.7398 40.2823C41.1137 36.9042 37.3726 34.5638 33.2064 33.6924C26.5059 32.2921 19.4613 34.8782 15.26 40.2823L17.7354 42.2065C21.2069 37.7411 27.0279 35.6033 32.5646 36.7613Z"
        fill="#DF130D"
      />
      <path
        d="M19.8334 25.6688C21.5649 25.6688 22.9686 24.2651 22.9686 22.5336C22.9686 20.8021 21.5649 19.3984 19.8334 19.3984C18.1019 19.3984 16.6982 20.8021 16.6982 22.5336C16.6982 24.2651 18.1019 25.6688 19.8334 25.6688Z"
        fill="#DF130D"
      />
      <path
        d="M39.2323 25.6688C40.9639 25.6688 42.3675 24.2651 42.3675 22.5336C42.3675 20.8021 40.9639 19.3984 39.2323 19.3984C37.5008 19.3984 36.0972 20.8021 36.0972 22.5336C36.0972 24.2651 37.5008 25.6688 39.2323 25.6688Z"
        fill="#DF130D"
      />
    </Svg>
  )
}

export default Icon
