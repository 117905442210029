import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

interface SvgPropsCustom extends SvgProps {
  active?: boolean
}

const Icon: React.FC<SvgPropsCustom> = ({ active, ...props }) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H21V10H3V3Z"
        stroke={active ? '#F5ECE2' : '#66605A'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14H21V21H3V14Z"
        stroke={active ? '#F5ECE2' : '#66605A'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />{' '}
    </Svg>
  )
}

export default Icon
