import React from 'react'
import { Text } from 'uikit'
import styled from 'styled-components'
import Container from 'components/Layout/Container'
import { Flex } from '../Box'
import {
  StyledToolsContainer,
} from './styles'
import { FooterProps } from './types'
import { LogoIcon } from '../Svg'
import { socials } from './config'
import IconComponent from '../Svg/IconComponent'

const SocialItem = styled.a`
  border: none;
`
const MenuItem: React.FC<FooterProps> = ({
  ...props
}) => {
  return (
    <Flex {...props} justifyContent="center" width='100%' pb='40px' pt='100px'>
      <Container width='100%'>
        <Flex flexDirection="column">
          {/* <StyledIconMobileContainer display={['block', null, 'none']}>
          <LogoWithTextIcon isDark width="130px" />
        </StyledIconMobileContainer> */}
          {/* <Flex
          order={[2, null, 1]}
          flexDirection={['column', null, 'row']}
          justifyContent="space-between"
          alignItems="flex-start"
          mb={['42px', null, '36px']}
        >
          {items?.map((item) => (
            <StyledList key={item.label}>
              <StyledListItem>{item.label}</StyledListItem>
              {item.items?.map(({ label, href, isHighlighted = false }) => (
                <StyledListItem key={label}>
                  {href ? (
                    <Link
                      href={href}
                      target="_blank"
                      rel="noreferrer noopener"
                      color={isHighlighted ? baseColors.warning : darkColors.text}
                      bold={false}
                    >
                      {label}
                    </Link>
                  ) : (
                    <StyledText>{label}</StyledText>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          ))}
          <Box display={['none', null, 'block']}>
            <LogoWithTextIcon isDark width="160px" />
          </Box>
        </Flex> */}
          {/* <StyledSocialLinks order={[2]} pb={['42px', null, '32px']} mb={['0', null, '32px']} /> */}
          <StyledToolsContainer
            order={[1, null, 3]}
            flexDirection={['column', null, 'row']}
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <LogoIcon width="40px" height="40px" />
            </Flex>
            <Flex alignItems="center">
              <Text letterSpacing="0.03em" fontSize="14px" fontWeight="500" color="text2">
                © 2022 NarcosLand. All rights reserved.
              </Text>
            </Flex>
            <Flex mb={['24px', null, '0']} style={{ gap: '20px' }} alignItems="center">
              {socials.map(({ label, icon, href }) => (
                <SocialItem href={href} key={label}>
                  <IconComponent iconName={icon} />
                </SocialItem>
              ))}
            </Flex>
          </StyledToolsContainer>
        </Flex>
      </Container>
    </Flex>
  )
}

export default MenuItem
