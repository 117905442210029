import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { SafegramTheme } from 'uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends SafegramTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Oswald', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    overflow-x: hidden !important;

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .pagination{
    display: flex;
    list-style:none ;
    justify-content: center;
    gap: 2px;

    li {
      a{
        background: ${({ theme }) => theme.colors.backgroundAlt2};
      color: ${({ theme }) => theme.colors.text2};;
      padding: 9px 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      display: block;
      }
    }

    .break{
      a{
        cursor: default;
      }
    }

    .disabled{
      a{
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .selected {
      a{
        background: ${({ theme }) => theme.colors.primary};
        cursor: default;
      }
    }
    .previous{
      a{
        margin-right: 9px;
      padding: 9px 19px;
      }
    }
    .next{
      a{
        margin-left: 9px;
      padding: 9px 19px;
      }
    }
  }
`

export default GlobalStyle
