import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { WrapperProps } from './types'

const StyledWrapper = styled.div<{ $width: number; $height: number }>`
  position: relative;
  width:  ${({ $width}) => $width}px;
  height:  ${({ $height}) => $height}px;

  &:after {
    content: '';
    display: block;
    padding-top: ${({ $width }) => ($width === 32 ? '100%' : '60px')};
  }

  ${space}
`

const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(({ width, height, ...props }, ref) => {
  return <StyledWrapper ref={ref} $width={width} $height={height} {...props} />
})

export default Wrapper
