import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg color="none"width="9" height="4" viewBox="0 0 9 4" fill="none" {...props}>
        <path d="M4.5 0L9 4L4.5 2.93333L0 4L4.5 0Z" fill="white" />
    </Svg>
  )
}

export default Icon
