import { Box } from 'uikit'
import styled from 'styled-components'

export const StyledPriceChart = styled(Box)<{ $isDark: boolean; $isExpanded: boolean }>`
  border: none;

  width: 100%;
  padding-top: 36px;
  background: ${({ theme }) => theme.colors.backgroundAlt};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 8px;
    background: ${({ theme }) => theme.colors.backgroundAlt};

    width: 100%;
    height: ${({ $isExpanded }) => ($isExpanded ? 'calc(100vh - 100px)' : '484px')};
  }
`

StyledPriceChart.defaultProps = {
  height: '70%',
}
