import React from 'react'
import { useMatchBreakpoints } from 'uikit/hooks'
import { Box } from '../Box'
import { MenuItemNew } from '../MenuItem/MenuItem'
import IconComponent from '../Svg/IconComponent'
import StyledSubMenuItems from './styles'
import { SubMenuItemsProps } from './types'

const SubMenuItems: React.FC<SubMenuItemsProps> = ({ items = [], activeItem, isMobileOnly = false, ...props }) => {
  const { isMobile } = useMatchBreakpoints()

  return (
    <StyledSubMenuItems
      justifyContent={['center']}
      style={{gap : isMobile ? '32px' : "46px"}}
      {...props}
      pl={['12px', null, '0px']}
      $isMobileOnly={isMobileOnly}
    >
      {items.map(
        ({ label, href, iconName }) =>
          label && (
            <Box key={label}>
              <MenuItemNew href={href} isActive={href === activeItem} variant="subMenu">
                {iconName && (
                  <IconComponent
                    color={href === activeItem ? 'secondary' : 'textSubtle'}
                    iconName={iconName}
                    mr="4px"
                  />
                )}
                {label}
              </MenuItemNew>
            </Box>
          ),
      )}
    </StyledSubMenuItems>
  )
}

export default SubMenuItems
