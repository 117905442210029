import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M10.6668 1.3335L9.3335 2.66683L10.6668 4.00016" stroke="white" />
    <path d="M13.3335 2.66683H9.3335" stroke="white" strokeLinejoin="round" />
    <path d="M13.9999 8.00016C13.9994 7.07435 13.7833 6.16137 13.3689 5.3335" stroke="white" strokeLinejoin="round" />
    <path d="M6.66666 2.15381C3.93775 2.77525 2.00124 5.20122 2 8" stroke="white" strokeLinejoin="round" />
    <path d="M5.3335 14.6667L6.66683 13.3333L5.3335 12" stroke="white" />
    <path d="M2.6665 13.3333H6.6665" stroke="white" strokeLinejoin="round" />
    <path d="M2.00023 8C2.0008 8.92581 2.21685 9.83879 2.63128 10.6667" stroke="white" strokeLinejoin="round" />
    <path d="M9.3335 13.8462C12.0624 13.2248 13.9989 10.7988 14.0002 8" stroke="white" strokeLinejoin="round" />
    <rect
      x="5.33447"
      y="8"
      width="3.76967"
      height="3.76967"
      rx="0.5"
      transform="rotate(-45 5.33447 8)"
      stroke="white"
    />
  </Svg>
)

export default Icon
