import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ activeStroke, ...props }) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 22C10.525 22 13.001 19.525 13.001 16.501C13.001 13.477 10.525 11 7.5 11C4.475 11 2 13.475 2 16.501C2 17.735 2.417 18.873 3.109 19.793C4.116 21.129 5.71 22 7.5 22Z"
        stroke={activeStroke || "#66605A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3501 19.8708H16.9791C19.2271 19.8708 21.0501 18.0478 21.0501 15.7998"
        stroke={activeStroke || "#66605A"}
        strokeWidth="1.5"
      />
      <path
        d="M9.6502 4.12891H8.0212C5.7732 4.12891 3.9502 5.95191 3.9502 8.19991"
        stroke={activeStroke || "#66605A"}
        strokeWidth="1.5"
      />
      <path d="M8.42871 2.90723L9.64971 4.12923L8.42871 5.35023" stroke={activeStroke || "#66605A"} strokeWidth="1.5" />
      <path
        d="M23 7.5C23 10.538 20.538 13 17.5 13C14.462 13 12 10.538 12 7.5C12 4.462 14.462 2 17.5 2C20.537 2 23 4.462 23 7.5"
        stroke={activeStroke || "#66605A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5711 21.0934L15.3501 19.8724L16.5711 18.6514" stroke={activeStroke || "#66605A"} strokeWidth="1.5" />
    </Svg>
  )
}

export default Icon
