import { Box, Flex } from 'uikit'
import styled from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;
`

export const StyledInputCurrencyWrapper = styled(Box)`
  min-width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 328px;
  }
`
// border: 1px solid ${({ theme }) => theme.colors.backgroundAlt2};
// flex-direction: column;
//
