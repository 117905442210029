import React from 'react'
import Link from './Link'
import { LinkProps } from './types'
import OpenNewIcon from '../Svg/Icons/OpenNew'

export interface LinkProps2 extends LinkProps {
  color?: string
}

const LinkExternal: React.FC<LinkProps2> = ({ children, color = '#DF130D', ...props }) => {
  return (
    <Link external {...props}>
      {children}
      <OpenNewIcon color={color} ml="4px" />
    </Link>
  )
}

export default LinkExternal
